import "./App.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "./config";
import { format } from "date-fns";
import Search from "./images/Search.png";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";

function App() {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [tab, setTab] = useState("photos");
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const lightColors = [
    "#CFC8FF", // AliceBlue
    "#FFCE73", // AntiqueWhite
    "#CDF4FF", // Beige
    "#FFEBF6", // Bisque
    "#FF9A7B", // BlanchedAlmond
  ];

  const groupByDate = (items) => {
    return items.reduce((groups, item) => {
      const date = item.date; // Extract date from each item
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, "do MMMM yyyy");
  }

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      try {
        // const {data: response} = await axios.get('https://picsum.photos/v2/list?page=2&limit=40');
        if (tab === "photos") {
          const { data: imagesRes } = await axios.post(
            `${API_BASE_URL}/v1/media/get`,
            { types: ["image"], limit: 100 }
          );
          const data = groupByDate(imagesRes.data);
          setImages(data);
        }
        if (tab === "videos") {
          const { data: videosRes } = await axios.post(
            `${API_BASE_URL}/v1/media/get`,
            { types: ["video"], limit: 100 }
          );
          const data = groupByDate(videosRes.data);
          setVideos(data);
        }
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchImages();
  }, [tab]);

  return (
    <div className="w-full h-full relative bg-white">
      <div className="flex md:hidden">
        <Sidebar
          backgroundColor="#ffffff"
          toggled={collapsed}
          onBackdropClick={handleCollapsedChange}
          breakPoint="md"
        >
          <h1 className="p-8 font-bold">Media Options: </h1>
          <Menu iconShape="circle">
            <MenuItem
              icon={<FaPhotoVideo />}
              active={tab === "photos"}
              onClick={() => {
                setTab("photos");
                handleCollapsedChange();
              }}
            >
              Photos
            </MenuItem>
            <MenuItem
              icon={<FaVideo />}
              active={tab === "videos"}
              onClick={() => {
                setTab("videos");
                handleCollapsedChange();
              }}
            >
              Videos
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>

      <div className="filter-options">
        {/* Tab Switches */}
        <div className="hidden md:flex gap-4 py-14 px-10 items-start">
          <button
            className={`px-6 py-2 rounded-2xl font-semibold ${
              tab === "photos" ? "bg-indigo-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setTab("photos")}
          >
            Photos
          </button>
          <button
            className={`px-6 py-2 rounded-2xl font-semibold ${
              tab === "videos" ? "bg-indigo-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setTab("videos")}
          >
            Videos
          </button>
        </div>
        <AiOutlineMenu
          onClick={handleCollapsedChange}
          size={30}
          className="flex md:hidden justify-center ml-8"
        />
        <div className="search-options">
          {/* Search Component */}
          <div className="flex items-center bg-[#f7f7fa] rounded-xl p-2 h-16 w-64">
            <img className="px-2" src={Search} alt="search" loading="lazy" />
            <input
              className="flex-grow p-2 bg-transparent outline-none"
              placeholder="Search"
            />
          </div>
        </div>
      </div>

      {/* Grid layout for media items */}
      {loading ? (
        <div className="flex justify-center items-center font-bold">
          LOADING...
        </div>
      ) : tab === "photos" ? (
        Object.entries(images).map(([date, items]) => (
          <div key={date}>
            <div className="flex ml-8">
              {/* <img src={Group1} alt='leading-date' className='pr-4 w-12'/> */}
              <h2 className="font-bold text-2xl flex text-[#323234]">
                {formatDate(date)}
              </h2>
            </div>
            <div className="media-grid">
              {items.map((item, index) => {
                const bgColor =
                  lightColors[Math.floor(Math.random() * lightColors.length)]; // Example for random background color
                return (
                  <div
                    key={index}
                    className="media-item"
                    style={{ backgroundColor: bgColor }}
                  >
                    <img src={item.url} alt={item.description} loading="lazy" />
                  </div>
                );
              })}
            </div>
          </div>
        ))
      ) : (
        Object.entries(videos).map(([date, items]) => (
          <div key={date}>
            <div className="flex ml-8">
              {/* <img src={Group1} alt="leading-date" className="pr-4 w-12" /> */}
              <h2 className="font-bold text-2xl flex text-[#323234]">
                {formatDate(date)}
              </h2>
            </div>
            <div className="media-grid">
              {items.map((item, index) => {
                const bgColor =
                  lightColors[Math.floor(Math.random() * lightColors.length)]; // Example for random background color
                return (
                  <div
                    key={index}
                    className="media-item"
                    style={{ backgroundColor: bgColor }}
                  >
                    <video controls>
                      <source src={item.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* )} */}
                  </div>
                );
              })}
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default App;
